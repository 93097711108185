import React from "react";
import * as Styled from "./LayoutStyles";
import Footer from "../components/headerAndFooter/Footer";
import GlobalStyle from "../constants/GlobalStyle";
import Header from "../components/headerAndFooter/Header";
import WaveComponent from "../components/wave/WaveComponent";
import {WaveArea} from "./LayoutStyles";

type IProps = {
    children?: React.ReactNode;
    heroArea?: JSX.Element;
    extraChildren?: JSX.Element|JSX.Element[];
    bodyBackgroundColor?: boolean;
    backgroundColor?: string;
    loginButtonText?: boolean;
    hamburgerMenuType?: 'white' | 'black';
    backgroundType?: 'linear' | 'none';
    hamburgerMenu?: boolean;
    loginButtonMenu?: boolean;
    registerButton?: boolean;
    sloganOne?: string;
    sloganTwo?: string;
    homeButton?: boolean;
    className?: string;
    waveComponent?: boolean;
    bgColor?: boolean;
    accountDelete?: boolean;
};

const WaveLayout = (props: IProps) => {

    return (
        <React.Fragment>

            <GlobalStyle bodyBackgroundColor={props.bodyBackgroundColor}/>
            <Styled.GeneralPage>

                <Header
                    headerBackground={'light'}
                    {...(props.loginButtonText ? {loginButton: true} : {registerButton: true})}
                    // loginButton
                    registerButton={props.registerButton}
                    sloganOne={props.sloganOne === '' ? props.sloganOne : 'Ayda 90 bin Yeni Kayıt'}
                    sloganTwo={props.sloganTwo === '' ? props.sloganTwo : '15 yıldır yanınızda'}
                    hamburgerMenuType={props.hamburgerMenuType}
                    hamburgerMenu={props.hamburgerMenu}
                    loginButtonMenu={props.loginButtonMenu}
                    homeButton={props.homeButton}
                    className={props.className}
                    bgColor={props.bgColor}
                    accountDelete={props.accountDelete}
                />

                {props.heroArea ? (
                    <Styled.SubpageLandingHeroArea
                        className={'heroArea'}
                    >
                        {props.heroArea}
                    </Styled.SubpageLandingHeroArea>
                ) : null}

                <WaveArea
                    backgroundColor={props.backgroundColor}
                    backgroundType={props.backgroundType}
                >
                    <WaveComponent waveComponent={props.waveComponent}/>
                    <div className={'wrapper'}>
                        {(props.children) ? (
                            props.children
                        ) : null}
                    </div>
                </WaveArea>
                {(props.extraChildren) ? (
                    props.extraChildren
                ) : null}
                <Footer/>
            </Styled.GeneralPage>

        </React.Fragment>
    );

};

export default WaveLayout;
