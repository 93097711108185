import React from "react";
import styled from "styled-components";

type IProps = {
    waveComponent?: boolean;
}

const WaveComponent = ({waveComponent = true}: IProps) => {

    return (
        <>
            {waveComponent && (
                <Wave className={'wave'}>
                    <svg
                        width="100%"
                        height="194"
                        viewBox="0 0 390 194"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M374.351 0H0L0.000732422 193.402C0.000732422 184.928 0.934242 176.454 3.00871 168.198C19.7081 100.408 89.2027 116.486 104.554 120.397C109.636 121.701 114.719 122.787 119.905 123.874C256.646 152.518 341.447 50.6872 374.351 0Z"
                            fill={'#FFFFFF'}/>
                    </svg>

                </Wave>
            )
            }
        </>
    )
};

const Wave = styled.div`
    width: 100%;
    height: 170px;
    position: absolute;
    top: -0px;
    left: 0;
`;

export default WaveComponent;
